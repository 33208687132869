import OurBar from "./NavBar"
function Notes() {
    return (
        <>
            <OurBar/>
            <br/>
            <center><h1 style={{color : "white"}}>Under Construction...</h1></center>
            <br/>
        </>

    )

}

export default Notes